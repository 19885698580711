import {AppRoutes} from './routes';
import { AuthProvider } from './context/AuthContext';

import './app.css'

function App() {
  return (  
    <AuthProvider>
      <AppRoutes/>
    </AuthProvider>    
  );
}

export default App;

