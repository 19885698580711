import { Container, Row, Col } from 'react-bootstrap/';

export function QuemSomos() {

  const apislide = "https://res.cloudinary.com/dcqngalxa/image/upload/v1672876403/spgrill/quem-somos-spgrill_rlegip.jpg";

  return (
    <Container style={{color: 'white', marginTop:30}}>
      <Row>
        <Col sm={6}>
          <img src={apislide} alt="imagemx" style={{maxWidth:'100%', borderRadius:10}}></img>
        </Col>
        <Col sm={6} className="text-scroll">
          <p></p>
          <h4 className="subtituloprincipal">Quem Somos</h4>
          
          <p>SP Grill surgiu do nosso desejo de criar equipamentos capazes de fascinar as pessoas, 
          entender o estilo e necessidade de cada projeto, cada sonho, pensando não somente na estética, 
          mas também na funcionalidade de cada equipamento e o melhor desempenho que ele possa atingir.
          </p>
          <p>Com mais de 30 anos desenvolvendo projetos exclusivos de Grills, Parrillas e os mais complexos sistemas de exaustão, unidos pela experiência e alta capacidade técnica.</p>

          <p>
          Queremos produzir equipamentos que contem uma história de excelência, exclusividade, durabilidade e praticidade. 
          Para isso, combinamos as mais modernas inovações técnicas de fabricação com os melhores materiais disponíveis. 
          Nosso tempo de experiência criando equipamentos em aço INOX nos permite garantir produtos inovadores que permanecerão 
          com aspectos de novo ao longo do tempo.
          </p>

          <h4 className="subtituloprincipal">Missão </h4>
          <p>Entender as expectativas depositadas em cada projeto, entregar qualidade, ótimas experiências em cada produto feito de forma exclusiva.</p>
          
          <h4 className="subtituloprincipal">Visão</h4>
          <p>Ser uma das três principais empresas do Brasil voltada para soluções em Exaustão, Grills e Parrillas.</p>
          
          <h4 className="subtituloprincipal">Valores</h4> 
          <p>Respeitar e manter a confiança depositada em nossa empresa, por nossos clientes e colaboradores. 
            Cuidar de sonhos exige alinhamento de ética e coerência, manter compromissos.
          </p>
        </Col>
      </Row>
    </Container>     
  );
}
