import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';

import { useAuth } from "../../context/AuthContext";
import { database, storage } from '../../services/firebase';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { FaRegTrashAlt } from "react-icons/fa";

import uuid from 'react-uuid';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import Input from '../../components/Form/input';
import DropzoneInput from "../../components/Form/dropzone";

import slide_null from '../../assets/slide_null.jpg';

export function HandleGallery() {

  const formCreateCard = useRef(null);
  const formUpDateCard = useRef(null);

  const { currentUser } = useAuth();

  const [init, setInit] = useState(0);
  const [finish, setFinish] = useState(5);

  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [visible, setVisible] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [modalZoomShow, setModalZoomShow] = useState(false);
  const [pause, setPause] = useState(false);
  const [imagemselect, setImagemSelect] = useState('');

  const [itemGalleryUpdate, setItemGalleryUpdate] = useState([])
  
  useEffect(() => {  
    const galleryRef = database.ref(`/gallery/`);
      galleryRef.on('value', (snapshot) => {      
        const posts = snapshot.val();

        const fullposts = [];
        for (let idposts in posts) {
          fullposts.push({ idposts, ...posts[idposts] });
        }
        setImages(fullposts);
        setLoading(false)
      });
      return () => {galleryRef.off('value');}
  }, [loading]);

  useEffect(() => {  
    const itemgalleryRef = database.ref(`/gallery/${imagemselect}`);
    setLoading(true)
      itemgalleryRef.on('value', (snapshot) => {      
        const itemgalery = snapshot.val();
        setItemGalleryUpdate(itemgalery);
        setLoading(false)
      });
      return () => {itemgalleryRef.off('value');}
  }, [imagemselect]);

  useEffect(() => {
    if(!pause){
      if(finish >= images.length + 5){
        setInit(0)
        setFinish(5)
      }
      const interval = setInterval(() => {
        setInit(init + 5)
        setFinish(finish + 5)
      }, 6000);
    return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [pause, init]);

function handleClickUpDateImage(id) {
  setPause(true);
  setImagemSelect(id);
  setModalShow(true);
}

function handleZoomModal(id) {
  setPause(true);
  setLoading(true);
  setImagemSelect(id);
  setLoading(false)
  setModalZoomShow(true);
}

async function handleCreateCardGallery(data, {reset}){
  
    const novoid = uuid();

    const newCard = database.ref(`gallery/${novoid}`);
    const newImage = storage.ref(`gallery/${novoid}`);
    const urldaimg = database.ref(`gallery/${novoid}/img`);

    try{
      const schema = Yup.object().shape({
        img: Yup.string().required("* obrigatório"),
        size: Yup.string().required("* obrigatório"),
      });
  
      await schema.validate(data, {
        abortEarly:false,
      })

    const { arquivos, img, size} = data;

    await newCard.set({ 
      id:novoid,
      img:img,
      size:`img-${size}`
    });

    if(arquivos[0]){
      await newImage.put(arquivos[0]);
      await newImage.getDownloadURL().then((url) => {urldaimg.set(url)});
    }
    formCreateCard.current.setErrors({});

    reset();

  }catch(err){
    if(err instanceof Yup.ValidationError){
      const errorMessages = {};
      err.inner.forEach(error => {
        errorMessages[error.path] = error.message;
      })
      formCreateCard.current.setErrors(errorMessages);
    }
  }  
}

async function handleUpDateCardGallery(data, {reset}){

  const novoid = uuid();

  const cardOfUpdate = database.ref(`gallery/${imagemselect}`);
  const newImage = storage.ref(`gallery/${novoid}`);
  const urldaimg = database.ref(`gallery/${imagemselect}/img`);

  try{
    const schema = Yup.object().shape({
      img: Yup.string().required("* obrigatório"),
      size: Yup.string().required("* obrigatório"),
    });

    await schema.validate(data, {
      abortEarly:false,
    })

    const { filesupdate, img, size} = data;

    await cardOfUpdate.update({ 
      img:img,
      size:`${size}`
    });

    if(filesupdate[0]){
      await newImage.put(filesupdate[0]);
      await newImage.getDownloadURL().then((url) => {urldaimg.set(url)});
    }

    formUpDateCard.current.setErrors({});
    
    setPause(false);
    setModalShow(false);

    reset();

  }catch(err){
    if(err instanceof Yup.ValidationError){
      const errorMessages = {};
      err.inner.forEach(error => {
        errorMessages[error.path] = error.message;
      })
      formUpDateCard.current.setErrors(errorMessages);
    }
  }finally{
    setPause(false);
  }
}

function handleClickRemoveImage() {
  setModalShow(false);
  const imageRef = database.ref(`/gallery/${imagemselect}`);
    confirmAlert({
      title: `Remover Foto?`,
      buttons: [
        {
          label: "SIM",
          onClick: async () =>{
            setLoading(true);
            await imageRef.set({});
            setImagemSelect([]);
            setPause(false);
            setLoading(false);
          } 
        },
        {
          label: "NÃO"
        }
      ]
    })
}

function ModalVertical(props) {

  const {img} = itemGalleryUpdate || [];

  return(
    <Modal
      {...props}
      className="font-bariol"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered >
      <Modal.Header closeButton>
      <h4 className="msgsucess">Altere as informações abaixo e clique em atualizar</h4>
      </Modal.Header>        

      <Modal.Body>
        <div className="img-modal">
          <img src={img} alt=""   
          onError={(e) => {
            e.target.onerror = 404
            e.target.onerror = null;
            e.target.src = slide_null
          }}
          />
        </div>
      <hr/>
      {currentUser &&
      <div className="update-post">
        
          <Form onSubmit={handleUpDateCardGallery} ref={formUpDateCard} initialData={itemGalleryUpdate}>
            <Row>
              <Col sm={12}>
                <DropzoneInput name="filesupdate"/>
              </Col>
              <Col sm={4}>
                <Input label="Url da Imagem" name="img"/>
              </Col>
              <Col sm={4}>
                <Input label="Digite: img-1x1 ou img-2x1" name="size"/>
              </Col>
              <Col sm={4}>
                <div className="form-actions-update-image-gallery">
                  <button type="submit" style={{marginTop:30, paddingBottom:5, paddingTop:5, backgroundColor:"#080"}}>
                    ATUALIZAR
                  </button>
                  <button style={{marginTop:30, paddingBottom:5, paddingTop:5,  backgroundColor:"#f00"}} onClick={()=>{handleClickRemoveImage()}}><FaRegTrashAlt style={{color:"#fff"}}/></button>
                </div>
              </Col>
            </Row>
          </Form>
      </div>}      
      </Modal.Body>
    </Modal>
    );
}

function ModalZoom(props) {

  const {img} = itemGalleryUpdate || [];

  return(
    // <Modal
    //   {...props}
    //   className="font-bariol"
    //   size="lg"
    //   aria-labelledby="contained-modal-title-vcenter"
    //   centered >
    
    //   <Modal.Body>
    //     <div className="img-modal" style={{marginTop:20}}>
    //       <img src={img} alt=""   
    //       onError={(e) => {
    //         e.target.onerror = 404
    //         e.target.onerror = null;
    //         e.target.src = slide_null
    //       }}
    //       />
    //     </div>  
    //   </Modal.Body>
    // </Modal>


      <Modal {...props} size="lg" centered className="bordermodalshow">
      
          <img src={loading ? slide_null : img} alt="" onClick={() => setModalZoomShow(false)}
          onError={(e) => {
            e.target.onerror = 404
            e.target.onerror = null;
            e.target.src = slide_null
          }}
          />
        
      </Modal> 
    );
}

const administrator = false;

  return(
    <>
    {imagemselect && <ModalVertical show={modalShow} onHide={() => {setModalShow(false); setPause(false);}}/>}

    {!currentUser && imagemselect && <ModalZoom show={modalZoomShow} onHide={() => {setModalZoomShow(false); setPause(false);}}/>}

    <Container className="gallery">
      <Row>
        <Col sm={12}> 
        <div className="galeria">
          {images?.map(image => 
            <img key={image.id} src={image.img} alt={'galeria waves'} className={image.size}
            onClick={()=>{currentUser ? handleClickUpDateImage(image.id): handleZoomModal(image.id) }}
          />)}          
        </div>
        </Col>
      </Row>

      {currentUser && administrator && 
        <Row>
          <Col sm={12} className="blog-title create-slide" style={{marginTop:40}}>
            <h2 className="font-bariol" style={{textAlign:'center'}} 
            onClick={()=>{setVisible(state => !state)}}>
              ADICIONE FOTOS AQUI! ({images.length}) {pause}
            </h2>
          </Col>
        </Row>
      }

      {visible && 
        <Row>
          <Col sm={12}>
            <Form onSubmit={handleCreateCardGallery} ref={formCreateCard}>
              <Row>
                <Col sm={12}>
                  <DropzoneInput name="arquivos"/>
                </Col>
                <Col sm={7}>
                  <Input label="Url da Imagem" name="img"/>
                </Col>
                <Col sm={2}>
                  <Input label="1x1 ou 2x1" name="size" defaultValue="1x1" placeholder="digite a proporção"/>
                </Col>
                <Col sm={3}>
                  <div className="form-posts">
                    <button type="submit" style={{width:'100%', marginTop:30, paddingBottom:10, paddingTop:10}}>ADICIONAR</button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      }
    </Container>
  </>
  )
}