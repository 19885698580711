import React, { useState } from 'react';
import { Container, Row, Col} from 'react-bootstrap/';
import { useNavigate } from 'react-router-dom';

import { useAuth } from "../../context/AuthContext";

import logo from '../../assets/logo.svg';

import { FaInstagram, FaRegEnvelope, FaUserCheck, 
  //FaUserTimes, 
  FaRegListAlt, FaUser} from 'react-icons/fa';

import './header.css'

export function Header() {
  const navigate = useNavigate();
  const { currentUser, logout } = useAuth();
  const [ error, setError ] = useState('');

  async function handleLogoutSubmit() {
    try {
      setError('')
      await logout();
    } catch(error) {
      setError(`Falha no Logout`);
    }
  }

  function handleGoTo(routerName){
    navigate(routerName);
    //console.log(routerName);
  }

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
      <Container>
        <Row>
          <Col sm={11} style={{textAlign: 'center', paddingTop:20}}>
          <img src={logo} className="logo-spgrill" alt="logo Sp Grill - Grill e Parrillas em Inox" onClick={() => handleGoTo('/')}/> 
          </Col>
          
          <Col sm={1}>
          <div className="item-spgrill">

          {!currentUser && 
            <>
            <p onClick={() => openInNewTab('https://www.instagram.com/sp.grill.inox/')}><FaInstagram/></p>
            <p onClick={() => openInNewTab('mailto:vendas@spgrill.com.br')}><FaRegEnvelope/></p>
            </>          
          }

          {currentUser? 
          <>
            <p onClick={() => handleLogoutSubmit()}><FaUserCheck style={{color:'#4DC247'}}/></p>
            <p onClick={() =>  handleGoTo('/contatos')}><FaRegListAlt style={{color:'#f90'}}/></p>
          </>
          :
            <p onClick={() => handleGoTo('/login')}><FaUser style={{color:'#fff', fontSize:14}}/></p>
          }          
          </div> 
          <p>{error}</p>

          </Col>
        </Row>
      </Container>     
  );
}