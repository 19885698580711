import React, {useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap/';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { useAuth } from "../../context/AuthContext";
import { database } from '../../services/firebase';

import { Header } from '../../components/Header';

import { FaRegClock, FaRegCheckCircle, FaTrashAlt } from 'react-icons/fa';

export function Contatos() {

  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [contatosList, setContatosList] = useState([]);

  useEffect(() => {
    let t = setTimeout(() => {
      if(!currentUser){
        navigate("/");
      }
    }, 100);
    return () => clearTimeout(t);
    // eslint-disable-next-line
  }, [currentUser]);

  useEffect(() => {  
    const contatosRef = database.ref(`/contatos/`);

      contatosRef.on('value', (snapshot) => {      
        const contatos = snapshot.val();
        const contatosList = [];
        for (let idcontato in contatos) {
          contatosList.push ({ idcontato, ...contatos[idcontato] });
        }
        setContatosList(contatosList);
        setLoading(false);
      });
      return () => {contatosRef.off('value');}
  }, [loading]);


  const [cliques, setCliques] = useState('');

  useEffect(() => {  
    const quantidadedecliques = database.ref(`/btnWhatsappHome/cliques`)
      quantidadedecliques.on('value', (snapshot) => {      
        const cliquesagora = snapshot.val();
        setCliques(cliquesagora);
        setLoading(false);
      });
      return () => {quantidadedecliques.off('value');}
  }, [loading]);

  console.log(cliques);

  async function handleUpdateStatusTrue(id) {
    const contato = database.ref(`/contatos/`).child(`${id}/`);
    setLoading(true);
    await contato.update({
      status:"Respondido"
    });
    setLoading(false);
  }

  async function handleUpdateStatusFalse(id) {
    const contato = database.ref(`/contatos/`).child(`${id}/`);
    setLoading(true);
    await contato.update({
      status:"Pendente"
    });
    setLoading(false);
  }

  async function handleRemoveContato(id){
    const contato = database.ref(`/contatos/`).child(`${id}/`);
    setLoading(true);

    confirmAlert({
      title: `excluir contato?`,
      message: `Realmente deseja excluir o contato ?`,
      buttons: [
        {
          label: "SIM",
          onClick: async () =>{
            await contato.remove();
          } 
        },
        {
          label: "NÃO EXCLIR"
        }
      ]
    });

    setLoading(false);
  }


  return (
    <>
    <Header/>
    <hr/>
        <Container style={{color:"#fff", marginBottom:100}}>

          <h3 style={{textAlign:"center", padding:50}}>Botão Atendimento Personalizado<br/>
           <strong style={{color: '#008000'}}>{currentUser && cliques} Cliques</strong>
           </h3>
          <h2 style={{textAlign:"center", padding:50}}>Lista de Contatos</h2>

        {loading ? <h4 style={{textAlign:"center", padding:50}}> Aguarde...</h4> : 
         contatosList?.map((contato, i) => (
            <div key={contato.idcontato} className="font-rbt">
              <Row className="list-orders">
                <Col md={2}>
                  {contato.name}
                </Col>  
                <Col md={3}>
                  {contato.email}
                </Col>   
                <Col md={2}>
                  {contato.whatsapp}
                </Col>
                <Col md={4}>
                  {contato.message}
                </Col>
                <Col md={1} style={{display:'flex', justifyContent: 'space-around'}}>
                  {contato.status === "Respondido" ?
                  <div style={{color:'#008000', cursor: 'pointer', margin:2}} onClick={() => handleUpdateStatusFalse(contato.idcontato)}><FaRegCheckCircle/></div> 
                  : 
                  <div style={{color:'#f90', cursor: 'pointer', margin:2}} onClick={() => handleUpdateStatusTrue(contato.idcontato)}> <FaRegClock/> </div>
                  }
                  <div style={{color:'#f00', cursor: 'pointer', margin:2}} onClick={() => handleRemoveContato(contato.idcontato)}><FaTrashAlt/></div>
                </Col>
              </Row>
            </div>
          ))}
          
      </Container>
    </>
   
  );
}