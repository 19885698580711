import React, { useState, useRef } from 'react';

import { Container, Row, Col, Modal } from 'react-bootstrap/';

import { database } from '../../services/firebase';

import { Form } from '@unform/web';
import Input from '../../components/Form/input';
import * as Yup from 'yup';

import orcamento from '../../assets/orcamento.png'

export function FaleConosco() {
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const formRef = useRef(null);

  async function handleSubmit(data, {reset}) {

    try{
      const schema = Yup.object().shape({
      name: Yup.string().required("* obrigatório"),
      email: Yup.string().email().required("* obrigatório"),
      whatsapp: Yup.string().required("* obrigatório"),
      message: Yup.string().required("* obrigatório"),
    });

    await schema.validate(data, {
      abortEarly:false,
    })

    const { name, email, whatsapp, message } = data;

      await database.ref(`contatos/`).push({
        name, 
        email, 
        whatsapp, 
        message,
        status: "Pendente"
      })

      setModalShow(true)
      setLoading(true)
      formRef.current.setErrors({});

      setTimeout(() => {
        setModalShow(false);
        setLoading(false)
      }, 4000);

     reset();  

    }catch(err){
      if(err instanceof Yup.ValidationError){
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        })
        formRef.current.setErrors(errorMessages);
      }
    }

  }

  function ModalCentralizado(props) {
    
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className="font-face-bs">Sucesso! Em breve retornaremos.</h4>
          </Modal.Title>
        </Modal.Header>        

        <Modal.Body>   
          <h4 className="font-face-bs msgsucess">Sua mensagem foi enviada com sucesso! </h4>
        </Modal.Body>
      </Modal>
    );
  }

  return(
    <>
      <Container style={{color:"#fff", marginBottom:100}}>
        <Row>
          <Col sm={12}style={{textAlign: 'center'}}>
            <h4 className="font-josefin">Entre em Contato</h4>
            <p>Complete o formulário abaixo. Hoje é um ótimo dia.</p>
            <hr/>
          </Col>
        </Row>
        <Row>
          <Col sm={5}  style={{textAlign: 'center'}}>
            <img src={orcamento} alt="orcamento spgrill"/>
          </Col>
          <Col sm={7}>
          <Form onSubmit={handleSubmit} className="form" ref={formRef}>
            <Input label="Nome" name="name"/>
            <Input label="E-mail" name="email"/>
            <Input label="WhatsApp" name="whatsapp"/>
            <Input label="Mensagem" name="message"/>
            <button type="submit" className="btn btn-enviar" style={{width: '100%', fontWeight:'bold'}} desabled={loading.toString()}>Enviar</button>
          </Form>
          </Col>
          <hr/>
        </Row>
      </Container>
      <ModalCentralizado show={modalShow} onHide={() => setModalShow(false)}
      />    
    </>
  )
}