import { Container, Row, Col } from 'react-bootstrap/'

import { Header } from '../../components/Header'
import { SliderHome } from '../../components/SliderHome'
import { Slogan } from '../../components/Slogan'
//import { Gallery } from '../../components/Photos'
import { HandleGallery } from '../../components/HandleGallery'
import { Frase } from '../../components/Frase'
import { FaleConosco } from '../../components/FaleConosco'
import { Telefones } from '../../components/Telefones'
import { Footer } from '../../components/Footer'
import { QuemSomos } from '../QuemSomos/'

import Testimonial from '../../components/Testimonials'

export function Home() {
  return (
    <Container fluid style={{ backgroundColor: '#111'}}>   
      <Header/>
      <SliderHome/>
      <Slogan/>
      <QuemSomos/>
      <HandleGalleryTitle/>
      <HandleGallery/>
      {/* <Gallery/> */}
      <Testimonial/>
      <Frase/>
      <FaleConosco/>
      <Telefones/>
      <Footer/>
    </Container>
  );
}

export function HandleGalleryTitle() {

  return(
    <Container style={{color:"#fff"}}>
      <Row style={{textAlign: 'center', paddingTop:150}}>
        <Col sm={12}>
          <h2 className="subtituloprincipal">Galeria de Projetos SPGrill</h2>
          <p style={{fontWeight:'bold'}}>Projetos Exclusivos, Personalizados e Únicos. Tudo sobre medida.</p>
        </Col>
      </Row>
    </Container>
  )
}
