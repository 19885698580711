import { Container, Row, Col } from 'react-bootstrap/';

export function Slogan() {

  return(
    <Container style={{color:"#fff"}}>
      <Row style={{textAlign: 'center', paddingTop:150}}>
        <Col sm={12}>
          <h2 className="subtituloprincipal">Coifas • Churrasqueiras • Parrillas • Kit's Fixos e Elevatórios</h2>
          <p style={{fontWeight:'bold'}}>Projetos Exclusivos, Personalizados e Únicos. Tudo sobre medida.</p>
        </Col>
      </Row>
    </Container>
  )
}
