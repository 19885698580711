import { Container, Row, Col, Carousel } from 'react-bootstrap/';

import './slidehome.css'

export function SliderHome() {

  const apislide = "https://spgrill.com.br/api/slide/";

  return (
    <Container fluid className='slidehome'>
       <Container  style={{color:"#fff"}}>
        <Row>
          <Col sm={12} style={{paddingTop:20, paddingBottom:20}} > 
          <Carousel style={{borderRadius:20, overflow: 'hidden'}} className='botasombra'>
            <Carousel.Item interval={3000} style={{borderRadius:20, overflow: 'hidden'}}>
              <img style={{borderRadius:20, overflow: 'hidden'}}
                className="d-block w-100"
                src={`${apislide}slide1.jpg`}
                alt="Coifa Inox"
                onClick={()=>{}}
              />
              {/* <Carousel.Caption>
                <h1 className="tituloslide">Coifa cilíndrica SpGrill</h1>
                <h2 className="subtituloslide">coifa em aço inox e filtros potentes</h2>
              </Carousel.Caption> */}
            </Carousel.Item>
            <Carousel.Item interval={3000} style={{borderRadius:20, overflow: 'hidden'}} >
              <img style={{borderRadius:20, overflow: 'hidden'}}
                className="d-block w-100"
                src={`${apislide}slide2.jpg`}
                alt="Coifa Cilíndrica"
                onClick={()=>{}}
              />
              {/* <Carousel.Caption>
                <h1 className="tituloslide">Coifa Cilíndrica SpGrill</h1>
                <h2 className="subtituloslide">coifa em aço inox e filtros potentes</h2>
              </Carousel.Caption> */}
            </Carousel.Item>
            <Carousel.Item interval={3000} style={{borderRadius:20, overflow: 'hidden'}}>
              <img style={{borderRadius:20, overflow: 'hidden'}}
                className="d-block w-100"
                src={`${apislide}slide3.jpg`}
                alt="Coifa Caixote"
                onClick={()=>{}}
              />
              {/* <Carousel.Caption>
                <h1 className="tituloslide">Coifa Caixote SpGrill</h1>
                <h2 className="subtituloslide">coifa em aço inox e filtros potentes</h2>
              </Carousel.Caption> */}
            </Carousel.Item>
          </Carousel>
          </Col>
        </Row>
      </Container>
  </Container>    
  );
}
