import React, {useState, useEffect} from 'react';
import {Container, Row, Col } from 'react-bootstrap/';

import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../context/AuthContext";

import { Header } from '../../components/Header';

import { Form } from '@unform/web';
import Input from '../../components/Form/input';


export function Login(){

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { login, currentUser } = useAuth();
  const [ user, setUser ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ error, setError ] = useState('');

  async function handleLoginSubmit() {

    try{
      setError('')
      setLoading(true)

      await login(user, password)
      setUser("");
      setPassword("")

      setLoading(false)
    } catch {
      setError(`Complete os campos corretamente!`)
      setLoading(false)
    }   
  }

  useEffect(() => {
    let t = setTimeout(() => {
      if(currentUser){
        navigate("/contatos");
      }
    }, 100);
    return () => clearTimeout(t);
    // eslint-disable-next-line
  }, [currentUser]);

  return(
    <>
    <Header/>
    <hr/>
    <Container className="formlogin font-face-bs">
      <Row >
        <Col sm={12} style={{padding:20}}>
          Faça seu Login com E-mail e Senha
        </Col>
      </Row>
      <Row>  
        <Col sm={2}></Col> 
        <Col sm={8}> 
            <Form onSubmit={handleLoginSubmit}>   
                <Input 
                  type="email"
                  name="email"
                  placeholder="digite seu email"
                  onChange={event => setUser(event.target.value)}
                  value={user}
                />            
                <Input
                  name="password"
                  type="password"
                  placeholder="digite sua senha"
                  onChange={event => setPassword(event.target.value)}
                  value={password}
                />
                  <button disabled={loading} type="submit" className="btn btn-enviar" style={{width:'100%'}}>
                    ENTRAR
                  </button>
              </Form>
              <p>{error}</p>           
          </Col>
      </Row>
    </Container>
    
    </>

   
   
  );
}