import React from 'react';
import { Route, Routes } from 'react-router-dom'

import { Login } from './screens/Login';

import { Home } from './screens/Home';
import { QuemSomos } from './screens/QuemSomos';
import { Contatos } from './screens/Contatos';

export function AppRoutes() {

  return (
    <Routes>     
      <Route path="/" element={<Home />} />
      <Route path="/empresa" element={<QuemSomos/>} />

      <Route path="/login" element={<Login/>} />
      <Route path="/contatos" element={<Contatos/>} />
    </Routes>
  );
}