import React, {useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap/';

import { FaPhoneSquareAlt } from 'react-icons/fa';

import { useAuth } from "../../context/AuthContext";
import { database } from '../../services/firebase';

export function Telefones() {
  const { currentUser } = useAuth();

  const [loading, setLoading] = useState(true);
  const [cliques, setCliques] = useState('');

  useEffect(() => {  
    const quantidadedecliques = database.ref(`/btnTelefonesHome/cliques`)
      quantidadedecliques.on('value', (snapshot) => {      
        const cliquesagora = snapshot.val();
        setCliques(cliquesagora);
        setLoading(false);
      });
      return () => {quantidadedecliques.off('value');}
  }, [loading]);

  async function handleNewContact() {
    const clicks = database.ref(`/btnTelefonesHome/`);
    setLoading(true);
    await clicks.update({
      cliques: cliques + 1
    });
    setLoading(false);
  }

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return(
    <Container fluid style={{color:"#fff", marginTop:100, marginBottom:100, paddingTop:30, paddingBottom:30}}>
      <Container>    
        <Row>
          <Col sm={12}style={{textAlign: 'center'}}>
            <h4 className="font-josefin">Entre em Contato pelos Telefones</h4>
            <hr/>
          </Col>
        </Row>
      </Container>        
     <Container>
        <Row style={{textAlign: 'center', paddingTop:20, paddingBottom:20}}>
          <Col sm={4}>
            <h4 style={{cursor:'pointer'}} 
            onClick={() => {
            openInNewTab('tel://1152421393');
            handleNewContact();
            }}>
              <FaPhoneSquareAlt style={{color:'#080'}}/> (11) 5242-1393
            </h4>
          </Col>
          <Col sm={4}>
            <h4 style={{cursor:'pointer'}}
            onClick={() => {
              openInNewTab('tel://11963489424');
              handleNewContact();
            }}>
              <FaPhoneSquareAlt style={{color:'#080'}}/> (11) 96348-9424
            </h4>
          </Col>
          <Col sm={4}>
            <h4 style={{cursor:'pointer'}} 
            onClick={() => {
            openInNewTab('tel://11998215139');
            handleNewContact();
            }}>
              <FaPhoneSquareAlt style={{color:'#080'}}/> (11) 99821-5139
            </h4>
          </Col>
        </Row>
        <Row>
          <Col sm={12} style={{textAlign: 'center', paddingBottom:20}}>
            {currentUser ? <h5 style={{paddingTop:30}}>{cliques} Cliques nos telefones a cima</h5> : ""}
          </Col>
        </Row>
      </Container>
  </Container>
  )
}
