import React, {useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap/';

import { useAuth } from "../../context/AuthContext";
import { database } from '../../services/firebase';

export function Frase() {
  const { currentUser } = useAuth();

  const [loading, setLoading] = useState(true);
  const [cliques, setCliques] = useState('');

  useEffect(() => {  
    const quantidadedecliques = database.ref(`/btnWhatsappHome/cliques`)
      quantidadedecliques.on('value', (snapshot) => {      
        const cliquesagora = snapshot.val();
        setCliques(cliquesagora);
        setLoading(false);
      });
      return () => {quantidadedecliques.off('value');}
  }, [loading]);

  async function handleNewContact() {
    const clicks = database.ref(`/btnWhatsappHome/`);
    setLoading(true);
    await clicks.update({
      cliques: cliques + 1
    });
    setLoading(false);
  }

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return(
    <Container fluid style={{color:"#000", backgroundColor:'#fff', marginTop:100, marginBottom:100, paddingTop:30, paddingBottom:30}}>
      <Row>
        <Col style={{textAlign: 'center', paddingTop:20, paddingBottom:20}}>
          <h4>Atendimento Personalizado</h4>           
          <button onClick={() => {
            openInNewTab('https://api.whatsapp.com/send?phone=5511963489424&text=Ol%C3%A1,%20tenho%20interesse%20em%20produtos%20da%20SPGrill');
            handleNewContact();
          }}  
          style={{padding:30, fontSize:20, fontWeight:'bold', borderRadius:20, borderWidth:0, backgroundColor:'#4DC247', color:'#fff'}}>
            Preciso de ajuda para escolher meus produtos
          </button>
          {currentUser ? <h3 style={{paddingTop:30}}>{cliques} Cliques</h3> : ""}
        </Col>
      </Row>
  </Container>
  )
}
