import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Avatar } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
//https://github.com/navvitech/carousels-in-react

import "./style.css";

const PreviousBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowBackIos style={{ color: "white", fontSize: "45px" }} />
    </div>
  );
};

const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowForwardIos style={{ color: "white", fontSize: "45px" }} />
    </div>
  );
};

const Testimonial = () => {
  return (
    <div className="testimonial font-josefin" style={{ display: "flex", justifyContent: "center", marginTop: 150, paddingBottom:50 }}>
      <div style={{ width: "50%", textAlign: "center" }}>

        <h1 style={{ marginBottom: 40, color: "white" }}>Depoimentos de <span>Clientes</span></h1>

        <Slider prevArrow={<PreviousBtn />} nextArrow={<NextBtn />} dots>
          
          <Card 
            img="https://res.cloudinary.com/dcqngalxa/image/upload/v1672910972/spgrill/depoimentos/luis_russo_ymmqch.png" 
            text="Projeto impecável SPGrill! Tenho um kit Suspenso em Inox 
            com Grelhas Parrilla e Argentina em 3 níveis de altura. 
            Além das grelhas, temos o descanso feito todo em aço inox. Material Robusto e de Alta qualidade. 
            Mão de obra exemplar na instalação do Kit. Parabéns SP GRill." 
            name="Luis Henrique Russo" 
            cargo="Dev. Full Stack"
          />

          <Card 
            img="https://res.cloudinary.com/dcqngalxa/image/upload/v1672871235/spgrill/depoimentos/mariana_kf9p6s.jpg"
            text="Adoramos tudo. Nossa área Gourmet ficou top, qualidade excelente, tudo personalizado!!" 
            name="Mariana Pacheco" 
            cargo="Maquiadora"
          />

          <Card 
            img="https://res.cloudinary.com/dcqngalxa/image/upload/v1672871235/spgrill/depoimentos/Paulo_Henrique_iidwi3.jpg" 
            text="Atendimento de primeira, nossa churrasqueira ficou linda. O projeto aproveitou muito bem todo o espaço." 
            name="Paulo Henrique" 
            cargo="Engenheiro"
          /> 

        </Slider>
      </div>
    </div>
  );
};

const Card = ({ img, text, name, cargo }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        color: "gray",
      }}
    >
      <Avatar
        imgProps={{ style: { borderRadius: "50%" } }}
        src={img}
        style={{
          width: 120,
          height: 120,
          border: "1px solid lightgray",
          padding: 7,
          marginBottom: 20,
        }}
      />

      <p style={{color: "white"}}>{text}</p>
      <p style={{ fontStyle: "italic", marginTop: 25 }}>
        <span style={{ fontWeight: 500, color: "green" }}>{name}</span>, {cargo}
      </p>
    </div>
  );
};

export default Testimonial;
