import { Container, Row, Col } from 'react-bootstrap/';

import logostnweb from '../../assets/logo_stnweb.png'

import './footer.css'

export function Footer() {
  return (
      <Container style={{color:"#fff"}} className="footer">   

        {/* <Row>
          <Col sm={4}>
            <p>QUEM SOMOS</p>
          </Col>
          <Col sm={4}>
            <p>REDES SOCIAIS</p>
          </Col>
          <Col sm={4}>
            <p>CONTATOS</p>
          </Col>
        </Row> */}

        <Row>
        <hr/>   
          <Col sm={12} style={{textAlign:"center", fontSize:14}}>
            <p>&copy; Todos os direitos reservados |  SP Grill Coifas e Churrasqueiras | CNPJ: 29.232.508/0001-05 | 
              <a href="mailto:vendas@spgrill.com.br" className="link-email">vendas@spgril.com.br</a>
              <span>Design
                <a href="https://stnweb.com.br" target="_blank" rel="noreferrer"><img src={logostnweb} alt="Logotipo StnWeb"/></a>
              </span>
            </p>
          </Col>
        </Row>

      </Container>     
  );
}
